import { Config } from "react-plotly.js";

export type LOADING_STATE = "pending" | "fulfilled" | "rejected";
export const TOAST_TIMEOUT = 10000;
export const DEFAULT_LOCALE = "en-US";

export const DEFAULT_PLOTLY_CONFIG: Partial<Config> = {
  displayModeBar: true,
  displaylogo: false,
  responsive: true,
};

export const BREAKDOWN_TOOL = "breakdown-tool";
export const INJURY_RATE_TOOL = "injury-rate-tool";
export const POLICY_QA_TOOL = "policy-qa-tool";

export const DEFAULT_SITE = "JFK8";

export const BREAKDOWN_QUESTIONS = (site) => [
  `Visualize the breakdown of total injuries by detailed body part at [${site}] in [March 2024].`,
  `Visualize the breakdown of Recordable Incident by tenure at [${site}] in [Jan 2024].`,
  `Visualize the breakdown of all the incidents by tenure at [${site}] between [August and September 2024]?`,
  `Visualize the 13-week breakdown of recordable incidents by [process path] at [${site}].`,
];

export const INJURY_RATE_QUESTIONS = (site) => [
  `What is the weekly RIR at [${site}] from March 2024 to May 2024?`,
  `What is the weekly MSD RIR at [${site}] from March 2024 to May 2024?`,
  `What is the weekly SIR at [${site}] from March 2024 to May 2024?`,
  `What is the monthly mature DART at [${site}] in 2024?`,
];

export const HIGH_LEVEL_ANALYSIS_QUESTIONS = () => [
  "Visualize the breakdown of total si by incident location in AR Sortable in 2024",
  "Visualize the breakdown of Recordable Incident by tenure at [Mexico] in [2024].",
  "What is the weekly RIR at NACF from March 2024 to May 2024?",
  "What is the SI at the following sites SMF6, SCK1, GEG2, FAT2, SBD2 in 2024?",
];

// For CloudWatch Rum Customer event
export const ALIAS = "alias";
export const SAFER_UPVOTE = "safer.upvote";
export const SAFER_DOWNVOTE = "safer.downvote";
export const QUESTION_ID = "question_id";

export const NEW_CHAT_CLICK = "safer.new_chat";
export const HISTORY_QUESTION_CLICK = "safer.history_question";
export const QUESTION_TEXT = "question_text";
export const EMPTY_ANSWER_QUESTION_TEXT = "empty_answer_question_text";
export const SESSION_ID = "session_id";
export const EMPTY_REASON = "empty_reason";

export const BREAKDOWN_QUESTION_COUNT = "safer.breakdown";
export const INJURY_RATE_QUESTION_COUNT = "safer.injury_rate";
export const POLICY_QA_QUESTION_COUNT = "safer.policy";
export const EMPTY_ANSWER_COUNT = "safer.empty_answer";
export const EMPTY_ANSWER_LLM_CANNOT_ANSWER =
  "safer.empty_answer.llm_cannot_answer";
export const TOOL_NAME = "tool_name";
export const FAILED_QUESTION_COUNT = "safer.failed_question";
export const POLICY_QA_QUESTION_SAIGE_MESSAGE = "safer.policy.saige";
